import Vue from 'vue'
import App from './App.vue'
// Vuesax Component Framework
import Vuesax from 'vuesax'
// Theme Configurations
import '../themeConfig.js'
// Globally Registered Components
import './globalComponents.js'
// Vue Router
import router from './router/index.js'
// Vuex Store
import store from './store/store'
// Vuesax Admin Filters
import './filters/filters'

import 'vuesax/dist/vuesax.css'
import 'material-icons/iconfont/material-icons.css';
import '@/assets/css/iconfont.css'
import '@/assets/css/main.scss'
import apiUrl from '../config'
// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer'
import themeConfig from "../themeConfig";
// Infinite scroll
import infiniteScroll from 'vue-infinite-scroll'
import axios from './axios';

import * as Sentry from "@sentry/vue";

Vue.prototype.$axios = axios;

Vue.use(Vuesax)

Vue.use(VueHammer)

Vue.use(infiniteScroll)


Vue.config.productionTip = false

Vue.prototype.$themeConfig = themeConfig

Vue.prototype.$apiUrl = apiUrl;

if(localStorage.user) {
    Vue.prototype.$user = JSON.parse(localStorage.user);
}

Sentry.init({
    Vue,
    dsn: "https://69048d0d76e6aa7983a85f1d921753bd@o4505765121359872.ingest.sentry.io/4505765212323840",
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://admin.europlast.hr"],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
